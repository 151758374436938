import axios from 'axios'

const cancelacionadmin = {

    PostCrear: (datos) => axios.post(`motivo-cancelacion-admin/crear-motivo-cancelacion`,datos),
    GetListar: (tipo) => axios.get(`motivo-cancelacion-admin/${tipo}/listar-motivo-cancelacion`),
    PutEditar: (datos) => axios.post(`motivo-cancelacion-admin/editar-motivo-cancelacion`,datos),
    PutEstado: (datos) => axios.post(`motivo-cancelacion-admin/estado-motivo-cancelacion`,datos),
    PostActualizarPosicion: (datos) => axios.post('motivo-cancelacion-admin/posicion-motivo-cancelacion', datos),
    Delete: (id,tipo) => axios.delete(`motivo-cancelacion-admin/${id}/${tipo}/eliminar-motivo-cancelacion`),

    //modulo inactivo
    GetListarinactivos: (tipo) => axios.get(`motivo-cancelacion-admin/${tipo}/listar-motivo-cancelacion-inactivo`),

}
export default cancelacionadmin
